import { Image, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';

import { CommodityList } from './CommodityList';
import { ImagePanel } from './ImagePanel';
import { useBuyModal } from './useBuyModal';

export const BuyModal = () => {
  const [isOpen, closeModal] = useBuyModal((s) => [s.isOpen, s.closeModal]);

  return (
    <Modal
      size="full"
      scrollBehavior="inside"
      onClose={closeModal}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{
          zIndex: 10001,
        }}
      >
        <Image
          cursor="pointer"
          position="absolute"
          src={CloseButton.src}
          alt="mission notification"
          right="20px"
          top="20px"
          onClick={closeModal}
        />
        <ImagePanel />
        <CommodityList />
      </ModalContent>
    </Modal>
  );
};
