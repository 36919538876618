import { fontFamily } from '@ballz-app/chakra';
import { Star, Steel } from '@ballz-app/icons';
import type { StackProps } from '@chakra-ui/react';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useBallzToast } from '@ui/hooks/useBallzToast';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';

import { StrokeText } from '../StrokeText';
import { Commodities } from './constants';
import { Disclaimer } from './Disclaimer';
import { useBuyModal } from './useBuyModal';
import { useGetInvoiceLink } from './useGetInvoiceLink';

const BallTextStyle = {
  color: '#3B254C',
  fontSize: '18px',
  fontFamily: fontFamily.luckiestGuy,
  pt: '5px',
};
const CommodityItem = ({
  balls,
  stars,
  ...props
}: { balls: number; stars: number } & StackProps) => {
  return (
    <HStack
      borderRadius="20px"
      border="3px solid #3B254C"
      width="100%"
      cursor="pointer"
      spacing={0}
      {...props}
    >
      <HStack
        p="15px"
        flex="1"
        bg="#F8FFD2"
        borderLeftRadius="16px"
        height="56px"
      >
        <Text {...BallTextStyle}>{balls} BALLS</Text>
        <Icon as={Steel} />
      </HStack>
      <HStack
        width="130px"
        justify="center"
        p="15px"
        bg="#F8478B"
        borderRightRadius="16px"
        height="56px"
        spacing="10px"
      >
        <StrokeText
          color="#47FCE9"
          strokeWidth="4px"
          strokeColor="#3B254C"
          fontSize="18px"
          pt="5px"
        >
          BUY
        </StrokeText>
        <Text {...BallTextStyle}>{stars}</Text>
        <Icon as={Star} />
      </HStack>
    </HStack>
  );
};

export const CommodityList = () => {
  const [selectedCommodityKey, setSelectedCommodityKey] = useBuyModal((s) => [
    s.selectedCommodityKey,
    s.setSelectedCommodityKey,
  ]);
  const tgId = useTgInfoStore((s) => s.user.id);
  const { mutateAsync: getInvoiceLink } = useGetInvoiceLink({ tgId });
  const queryClient = useQueryClient();
  const toast = useBallzToast();
  // const commodities = useMemo(() => {
  //   return process.env.APP_ENV === 'stag'
  //     ? CommoditiesStagMockData
  //     : Commodities;
  // }, []);

  return (
    <VStack
      flex="1"
      height="100%"
      bg="#0DDCC7"
      spacing="12px"
      overflow="scroll"
      p="20px"
    >
      {Commodities.map((commodity, idx) => (
        <CommodityItem
          balls={commodity.balls}
          stars={commodity.stars}
          key={commodity.balls}
          onClick={async () => {
            try {
              const tg = window.Telegram?.WebApp;
              setSelectedCommodityKey(idx);
              const response = await getInvoiceLink({
                stars: commodity.stars,
                balls: commodity.balls,
              });
              const result = await tg?.openInvoice(
                response.invoice_link,
                (status: string) => {
                  if (status === 'paid') {
                    // status: cancelled / failed / paid
                    queryClient.invalidateQueries({
                      queryKey: ['game.setting', { tgId }],
                    });
                    toast({
                      status: 'success',
                      description: `You have successfully bought ${commodity.balls} Ballz! Time to play!`,
                    });
                  } else {
                    console.log('Payment failed.');
                  }
                },
              );

              console.log('result--------', result);
            } catch (e) {
              console.error(e);
            }
          }}
          boxShadow={
            selectedCommodityKey === idx ? 'none' : '4.3px 4.3px #3B254C'
          }
        />
      ))}
      <Disclaimer />
    </VStack>
  );
};
