export const Commodities = [
  {
    balls: 500,
    stars: 50,
  },
  {
    balls: 750,
    stars: 75,
  },
  {
    balls: 1000,
    stars: 100,
  },
  {
    balls: 1500,
    stars: 150,
  },
  {
    balls: 2500,
    stars: 250,
  },
  {
    balls: 3500,
    stars: 350,
  },
  {
    balls: 5000,
    stars: 500,
  },
];

export const CommoditiesStagMockData = [
  {
    balls: 50,
    stars: 5,
  },
  {
    balls: 75,
    stars: 10,
  },
  {
    balls: 100,
    stars: 11,
  },
  {
    balls: 150,
    stars: 15,
  },
  {
    balls: 250,
    stars: 20,
  },
  {
    balls: 350,
    stars: 50,
  },
  {
    balls: 500,
    stars: 1,
  },
];
